import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  Box, 
  Button,
  Link,
  Container, 
  Typography, 
  Grid
} from '@mui/material';

import ProductImage from "../../../assets/images/products.png";

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import EnergySavingsLeafTwoToneIcon from '@mui/icons-material/EnergySavingsLeafTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import styles from "../../../global.scss";
import "../styles.scss";

const ExpenseManagementPage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  // stepper
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      // ...theme.applyStyles('dark', {
      //   backgroundColor: theme.palette.grey[800],
      // }),
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    // ...theme.applyStyles('dark', {
    //   backgroundColor: theme.palette.grey[700],
    // }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
      },
    ],
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <strong>
          {icons[String(props.icon)]}
        </strong>
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = [
    'Team members submit expenses and upload receipts through the platform from anywhere in the world', 
    'Expenses are automatically categorized, converted to your base currency, and marked as billable or non-billable', 
    "Submitted expenses go through the custom approval workflow",
    'Approved expenses are automatically scheduled for reimbursement',
    "Funds are transferred directly to the team member's bank account in USD or their local currency",
    "All transactions are recorded for easy reporting and analysis, with automatic currency conversion for consolidated reporting",
  ];

  return (
    <Container maxWidth="xl">
      <Box className="products">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Products</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={ProductImage}
              alt='product'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box className="products__section" 
          id={"web-app"}>
          <Typography variant='h4' mb={"60px"}>Expense management</Typography>
          {/* <Typography variant='h5' textAlign={"left"} mb={"20px"}>
            Streamline Your Global Accounts Payables
          </Typography> */}
          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }} 
            mb={"20px"}>
            Our Expense Management solution empowers SMBs to efficiently track, approve, and reimburse business expenses worldwide, improving financial control and satisfaction for employees, contractors, and gig workers across the globe.
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "40px" }}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<UploadFileIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Employee Expense Submission</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Allow team members worldwide to easily submit expenses and upload receipts directly through the platform.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<ReceiptLongOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Receipt Management</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Capture and store digital receipts from any country, eliminating the need for paper records and simplifying global expense tracking.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<CategoryOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Expense Categorization</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Automatically categorize expenses and track whether they are billable or non-billable, regardless of currency or country.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<TaskAltOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Custom Approval Workflows</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Create tailored approval processes to ensure proper oversight and control over expense management.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LanguageOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Direct International Reimbursement</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Automatically reimburse approved expenses directly to team members' bank accounts, wherever they are in the world.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<EqualizerOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Global Reporting and Analytics</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Generate detailed reports on expense patterns, categories, and billable vs. non-billable expenses across all your international operations.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Box
            mt={"150px"}
            className="flexCenterCenterColumn">
            <Typography variant='h5' textAlign={"left"}>
              Benefits for your business
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <TimelapseOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Reduce time</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Streamline expense reporting and approval processes
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <VisibilityOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Visibility</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Analytics on business expenses across categories and countries
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <InsertEmoticonOutlinedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Employee delight</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Improve satisfaction for employees with quick, direct reimbursements
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={"100px"}
            className="flexCenterCenterColumn"
            gap={"40px"}>
            <Typography variant='h5' textAlign={"left"} mb={"10px"}>
              How it works
            </Typography>
            <Stepper alternativeLabel activeStep={7} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      variant='caption'>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
            mt={"120px"}
            className="flexCenterCenterColumn"
            gap={"20px"}>
            <Typography variant='h5' textAlign={"left"} mb={"20px"}>
              Ready to optimize your global expense management?
            </Typography>
            <Typography variant='body1' 
              sx={{ color: "grey", marginBottom: "20px" }} 
              textAlign={"left"}>
              Sign up now and start streamlining your international business expenses!
            </Typography>
            <Link 
              href="https://business.lusid.ai/auth"
              target="_blank" rel="noopener noreferrer"
              >
              <Button variant='outlined'>Get started</Button>
            </Link>
          </Box>
        </Box>
      </Box>
		</Container>
  )
}

export default ExpenseManagementPage;