import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Box, 
	Container, 
	Grid, 
	IconButton, 
	Link,
	Typography 
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import FedNowIcon from "../../assets/icons/fed-now.png";
import AchIcon from "../../assets/icons/ach.png";
import RtpIcon from "../../assets/icons/rtp.png";

import "./styles.scss";

const Footer = () => {
	const navigate = useNavigate();

	const handleNavigate = (route) => {
		handleGoTop();
		navigate(route);
	}

	const handleGoTop = () => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

  return (
    <Box className="footer">
      <Container maxWidth="xl">
				<Box>
					<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
						<img
							src={Logo}
							alt='lusid-footer-logo'
							style={{ width: "250px" }}
							/>
					</Box>

					<Grid 
						container
						columnSpacing={1}
						rowSpacing={6}
						className="flexFSSBRow" 
						sx={{ paddingTop: "40px", paddingBottom: "50px" }}>	
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'>Products</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products/accounts-payable")}>Accounts payable</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products/accounts-receivable")}>Accounts receivable</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products/expense-management")}>Expense management</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/capabilities")}>Capabilities</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#instant-payment")}>Instant Payment</Typography>
							{/* <Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#issue-virtual-card")}>Issue Virtual Card</Typography> */}
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#control")}>Policy compliance</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#tap-to-pay")}>Tap to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#scan-to-pay")}>Scan to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#easy-bill-capture")}>Easy Bill Capture</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#pay-circle")}>Pay Circle</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#vendor")}>Payee/Vendor Management</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#automation")}>Spend Automation</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#expense-management")}>Expense Management</Typography>
							{/* <Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#bio")}>Biometrics Payment</Typography> */}
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#solutions")}>Solutions</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Solutions Overview</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Never miss a bill</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Control & reconcile spend</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Policy Compliance</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#developers")}>Developers</Typography>
							<Link
								href="https://developer.lusid.ai"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Developer Portal</Typography>
							</Link>
							<Typography variant='h6' className='footer__heading'
								sx={{ marginTop: "70px" }}
								onClick={() => handleNavigate("/pricing")}>Pricing</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'>Company</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/about-us")}>About us</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/contact-us")}>Contact us</Typography>

							<Typography variant='h6' className='footer__heading' sx={{ marginTop: "40px" }}>Legal</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/terms-of-service")}>Terms of service</Typography>
							<Link
								href="https://www.currencycloud.com/wp-content/uploads/2024/08/Currencycloud-Terms-UK-TCCL-15-August-24.pdf"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>CC terms</Typography>
							</Link>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/security")}>Security</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/privacy-policy")}>Privacy</Typography>
						</Grid>
					</Grid>
					<Box className="flexCenterSBRow"
						sx={{
							flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
						}}>
						<Box>
							<Typography
								color={"white"}
								textAlign={"end"}
								variant='caption'>
								© Copyright 2024 Lusid Inc.
							</Typography><br/>
							<Typography
								color={"lightgrey"}
								textAlign={"end"}
								variant='caption'>
								Lusid.ai and LusidPay.com are part of Lusid Inc.<br/>
							</Typography>
						</Box>

						<Box
							className="flexCenterCenterColumn"
							gap={"10px"}
							sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<Box>
								<Typography
									textAlign={"center"}
									color={"lightgrey"}
									variant='caption'>
									Proudly working with the following technology networks:
								</Typography>
							</Box>
							<Box 
								className="flexCenter_Row"
								gap={"20px"}
								sx={{
									flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
								}}
								>
								<img	
									src={FedNowIcon}
									alt='fed-now'
									style={{
										height: "40px",
									}}
									/>
								<img	
									src={RtpIcon}
									alt='rtp'
									style={{
										height: "50px"
									}}
									/>
								<img	
									src={AchIcon}
									alt='ach'
									style={{
										height: "50px"
									}}
									/>
							</Box>
						
						</Box>
					</Box>


					<Box
						className="flexCenterCenterColumn"
						gap={"10px"}
						sx={{ paddingTop: "20px", paddingBottom: "40px" }}>
						<Typography
							textAlign={"center"}
							color={"lightgrey"}
							variant='caption'>
							Lusid is a financial technology company. Payment services in the United States are provided by Visa Global Services Inc. (VGSI), a licensed money transmitter (NMLS ID 181032) in the states listed&nbsp;
							<Link href='https://usa.visa.com/legal/visa-global-services-licenses.html'
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "underline", fontSize: "14px" }}>
								here
							</Link>.  VGSI is licensed as a money transmitter by the New York Department of Financial Services. Mailing address: 900 Metro Center Blvd, Mailstop 1Z, Foster City, CA 94404. VGSI is also a registered Money Services Business ("MSB") with FinCEN and a registered Foreign MSB with FINTRAC. For live customer support contact VGSI at (888) 733-0041.
						</Typography>
					</Box>


					<Box className="flexCenterSBRow">
						<IconButton onClick={handleGoTop}>
							<ArrowUpwardIcon fontSize='large' sx={{ color: "white"}} />
						</IconButton>

						<Box  sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							
						</Box>
						{/* <Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<IconButton>
								<LinkedInIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
							<IconButton>
								<XIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
						</Box> */}
					</Box>
				</Box>
			</Container>
    </Box>
  )
}

export default Footer;