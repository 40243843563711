import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import CapableImage from "../../assets/images/capable.png";

// import styles from "../../global.scss";
import "./styles.scss";

const CapabilitiesPage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

  return (
    <Container maxWidth="xl">
      <Box className="capable">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Capabilities</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={CapableImage}
              alt='capable'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box className="capable__section"
          id={"instant-payment"}>
          <Typography variant='h4' mb={"40px"}>Instant Payment</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionizing the Way You Transact</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Instant bank account transfers have revolutionized the way we manage our finances. 
            Services like Fast ACH, FedNow, and others have made it possible to transfer money 
            between bank accounts almost instantaneously, eliminating the need to wait days for 
            traditional bank transfers to clear. These real-time payment systems allow individuals 
            and businesses to quickly send and receive funds, improving cash flow management and 
            enabling faster access to money.<br/><br/>
            Whether you're getting paid, paying a bill, or transferring money between your own 
            accounts, these instant transfer options provide a seamless and convenient banking 
            experience that keeps pace with the demands of modern life.
          </Typography>
        </Box>

        {/* <Box className="capable__section"
          id={"issue-virtual-card"}>
          <Typography variant='h4' mb={"40px"}>Issue Virtual Card</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>The Rise of Cards with Controls</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            In today's fast-paced digital era, virtual cards have emerged as a revolutionary 
            solution in the realm of payments. Essentially, virtual cards serve as digital 
            counterparts to physical credit or debit cards. They are specifically designed 
            for single-use or limited transactions and are typically associated with a 
            particular vendor or payment purpose. These cards can be utilized for various 
            purposes such as online purchases, subscription services. However, their true 
            value lies in their unparalleled ability to exercise control and monitor transactions 
            like never before.<br/><br/>
            With our cutting-edge Lusid web and mobile applications, you can effortlessly 
            create virtual cards in no time. These can be used for purchases at any online 
            merchant or in person at POS terminal that accept "tap to pay" payments. 
            In addition, Lusid apps offer a plethora of supplementary benefits, like real-time 
            visibility into transactions, and robust reporting capabilities, simplifying 
            the process of monitoring and analyzing expenses.
          </Typography>
        </Box> */}

        <Box className="capable__section"
          id={"control"}>
          <Typography variant='h4' mb={"40px"}>Policy Compliance</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Enhancing Compliance with Lusid products</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Imagine this scenario: You have been entrusted with the crucial task of managing
            your company&#39;s expenses, and to ensure that employees adhere to the budget
            while maintaining 100% compliance with the company&#39;s policies. This is where
            Lusid&#39;s web and mobile apps come into play. With Lusid, you can establish
            predefined spending limits, policy checks, regulate transaction types, and even
            specify preferred vendors. These controls empower you, but they also eliminate
            the need for laborious expense reconciliation processes. Who would have
            thought that assuming the role of a financial gatekeeper could be this effortless?<br/><br/>
            By seamlessly integrating controls and compliance features directly into Lusid&#39;s
            platform, you can now enjoy peace of mind, knowing that all your financial
            transactions are fully compliant with the company&#39;s policies, industry regulations,
            and even government requirements. It&#39;s like having a virtual compliance officer
            on your team, without the burden of paperwork and lengthy meetings.
          </Typography>
        </Box>


        <Box className="capable__section"
          id={"tap-to-pay"}>
          <Typography variant='h4' mb={"40px"}>Tap to Pay</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Embracing the Future: The Rise of Tap to Pay</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Tap to Pay revolutionizes the way you make payments by introducing a seamless
            contactless payment method. With a simple tap of your card, smartphone, or
            smartwatch on a compatible payment terminal, you can effortlessly complete
            transactions without the need for swiping or inserting.<br/><br/>
            This innovative payment solution not only saves you time but also prioritizes your
            security. Your payment information is encrypted, ensuring that cunning fraudsters
            are unable to access your hard-earned money. Additionally, the inclusion of
            biometric authentication on smartphones and smartwatches adds an extra layer
            of protection, guaranteeing that only you can authorize transactions.<br/><br/>
            To get started, all you need to do is download our user-friendly mobile app and
            effortlessly add your card details. With Tap to Pay, you&#39;ll have your very own
            personal payment assistant conveniently accessible in your pocket.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"scan-to-pay"}>
          <Typography variant='h4' mb={"40px"}>Scan to Pay</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>The Future of Effortless Shopping</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            In today&#39;s era of smartphones and digital wallets, traditional payment methods
            are undergoing rapid transformation. The emergence of &#39;Scan to Pay&#39; has
            revolutionized the way customers make purchases, offering a convenient and
            secure payment option. By simply scanning a QR code provided by the
            merchant, customers can complete transactions using their smartphones,
            eliminating the need for cash, physical cards, or even contact with payment
            terminals.<br/><br/>
            The process of using &#39;Scan to Pay&#39; is incredibly straightforward. All that is
            required is a smartphone equipped with the Lusid mobile app and a participating
            merchant who supports this payment method. Once customers have selected
            their desired items and are ready to pay, they can effortlessly open the app, scan
            the QR code displayed by the merchant, verify the transaction details, and within
            seconds, the payment is successfully completed.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"easy-bill-capture"}>
          <Typography variant='h4' mb={"40px"}>Easy Bill Capture</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Streamline Your Bill/Invoice Management</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Efficiently managing bills and invoices can often become a time-consuming and
            monotonous task for individuals and businesses alike, regardless of their size.
            The manual entry of data and the constant tracking of payments can lead to
            errors and inefficiencies within the process. This is precisely where Easy Bill
            Capture steps in.<br/><br/>
            Our cutting-edge Bill Capture solution is a robust tool meticulously crafted to
            streamline and automate your bill and invoice management process. Leveraging
            advanced optical character recognition (OCR) technology, it swiftly and
            accurately extracts data from invoices, eliminating the need for manual data
            entry. By doing so, it not only saves you valuable time but also significantly
            reduces the risk of human error. Discover the transformative power of Easy Bill
            Capture today and witness the remarkable difference it can make for you.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"pay-circle"}>
          <Typography variant='h4' mb={"40px"}>Pay circle / Spend budgets</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Spend Management for Individuals & SMBs with Innovative SaaS Solution</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Efficiently managing your family&#39;s finances can be approached with confidence
            and ease. By cultivating transparent communication, establishing collective
            objectives, and embracing the power of technology, you can pave the way for a
            prosperous and enduring financial future for your cherished ones. <br/><br/>
            Meanwhile, entrepreneurs and business owners are perpetually engaged in a
            multitude of responsibilities to ensure the seamless operation of their small or
            medium-sized enterprises. However, amidst the hustle and bustle, spend
            management often falls by the wayside. But fret not! We present to you the
            ultimate remedy - a cutting-edge SaaS solution that will revolutionize the manner
            in which you handle your company&#39;s expenditures.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"vendor"}>
          <Typography variant='h4' mb={"40px"}>Payee / Vendor management</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Simplifying Payee / Vendor Management for Individuals /
SMBs</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Payee/Vendor management offers a comprehensive view of your financial
            obligations, providing you with a transparent understanding of your expenses. By
            consolidating all your payees in a single location, you gain valuable insights that
            enable you to budget more effectively and make well-informed financial choices.<br/><br/>
            By adopting a systematic approach to vendor selection and monitoring, you can
            enhance quality control, minimize expenses, optimize efficiency, and mitigate
            potential risks. Although it may demand some initial effort to establish, the long-
            term advantages make it a highly worthwhile investment.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"automation"}>
          <Typography variant='h4' mb={"40px"}>Spend Automation</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Are you exhausted from dealing with manual processes and complex expense
            management? Step into a new era of efficiency and control with Spend
            Automation, powered by Payables automation and Self-Contained Payment Instructions.
            Streamline your financial operations and revolutionize your expense
            management!<br/><br/>

            Envision a payment system that comes with its own set of instructions. Payables automation
            equipped with self-contained payment instructions provide you with full
            authority over fund allocation. Effortlessly set limits, categories, and expiry dates.
            Seamlessly integrate spending rules into each payment, allowing you to
            specify spending limits, merchant categories, and validity periods to customize
            each transaction according to your unique requirements.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"expense-management"}>
          <Typography variant='h4' mb={"40px"}>Expense Management</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Control Your Financial Journey</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Navigating the complexities of business finances can be a daunting task, 
            but with the right expense management tools and strategies, you can take 
            control of your spending and achieve your financial goals. Whether you're 
            tracking monthly bills, managing employee reimbursements, or monitoring 
            project costs, effective expense management is the key to unlocking financial 
            clarity and stability.<br/><br/>
            By leveraging Lusid’s intuitive expense tracking app, policy controls, 
            customized approval workflows, and real-time reporting, you can gain a 
            comprehensive understanding of where your money is going. This empowers 
            you to make informed decisions, identify areas for cost savings, and allocate 
            resources more effectively. With expense management at the forefront, you can 
            streamline your financial journey, reduce stress, and focus on the priorities 
            that matter most to you or your business.
          </Typography>
        </Box>

        {/* <Box className="capable__section"
          id={"bio"}>
          <Typography variant='h4' mb={"40px"}>Biometrics Payment</Typography>
          <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>The Future is here: you don’t need to carry anything to make a payment</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Picture this: you&#39;re in a rush to buy that trendy new gadget you&#39;ve been eyeing,
            but you forgot your wallet at home. No worries! Thanks to the advancements in
            biometric payment technology, all you need is a simple fingerprint scan or a quick
            facial recognition, and presto! Your transaction is seamlessly completed.
            Intriguing, isn&#39;t it?<br/><br/>
            Biometric payment is no longer a mere concept from science fiction; it has
            become a burgeoning trend in the realm of technology-driven commerce.<br/><br/>
            Naturally, as with any novel technology, there are certain concerns that arise.
            Privacy, undoubtedly, tops the list. The notion of our biological data being stored
            in a database can understandably make some individuals uneasy. However, the
            reassuring news is that biometric payment systems are meticulously designed
            with privacy as a paramount consideration. Your biometric data is encrypted and
            securely stored, ensuring that only you have access to it.
          </Typography>
        </Box> */}
      </Box>
    </Container>
  )
}

export default CapabilitiesPage;