import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  Box, 
  Button,
  Link,
  Container, 
  Typography, 
  Grid
} from '@mui/material';

import ProductImage from "../../../assets/images/products.png";

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import EnergySavingsLeafTwoToneIcon from '@mui/icons-material/EnergySavingsLeafTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import PublicIcon from '@mui/icons-material/Public';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LoopIcon from '@mui/icons-material/Loop';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CellTowerIcon from '@mui/icons-material/CellTower';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import styles from "../../../global.scss";
import "../styles.scss";

const AccountsPayablePage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  // stepper


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    // ...theme.applyStyles('dark', {
    //   backgroundColor: theme.palette.grey[800],
    // }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  // ...theme.applyStyles('dark', {
  //   backgroundColor: theme.palette.grey[700],
  // }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      <strong>
        {icons[String(props.icon)]}
      </strong>
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};



  const steps = [
    'Create or import vendor invoices in any supported currency', 
    'Set up custom approval workflows based on your business needs', 
    'Schedule payments according to your preferred timeline',
    "Payments go through the approval process you've established",
    "Once approved, payments are sent to vendors' bank account in their local currency",
    'Track all transactions and manage your cash flow from a single dashboard'
  ];


  return (
    <Container maxWidth="xl">
      <Box className="products">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Products</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={ProductImage}
              alt='product'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box className="products__section" 
          id={"web-app"}>
          <Typography variant='h4' mb={"60px"}>Accounts payable</Typography>
          {/* <Typography variant='h5' textAlign={"left"} mb={"20px"}>
            Streamline Your Global Accounts Payables
          </Typography> */}
          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }} 
            mb={"20px"}>
            Our Accounts Payable solution empowers SMBs to create, manage, and pay vendor invoices with ease, whether they're domestic or international, one-time or recurring payments.
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "40px" }}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<PublicIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Global Payment Capabilities</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Make payments to vendors in US and in over 38 currencies across 180 countries, expanding your business reach globally.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<TaskAltIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Custom Approval Workflows</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Set up tailored approval processes for payments, ensuring proper oversight and control over your expenditures.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LocalAtmTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Bill Management</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Create and manage vendor bills effortlessly, keeping all your payables organized in one place, regardless of currency or country.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LoopIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Recurring Payments</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Set up and automate recurring payments, both domestic and international, saving time and ensuring timely payments to your vendors.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<CalendarMonthIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Flexible Payment Scheduling</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Schedule payments in advance, giving you better control over your global cash flow.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<TuneTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Flexibility and control</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Choose the payment delivery speed that suits your needs: Same Day, Next Day or 2-3 Business Days 
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Box
            mt={"150px"}
            className="flexCenterCenterColumn">
            <Typography variant='h5' textAlign={"left"}>
              Benefits for your business
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <CellTowerIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Global network</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Expand your vendor network globally with multi-currency support
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <ControlCameraIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Control</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Enhance financial control with custom approval workflows
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <HourglassEmptyIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Time is money</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
                      Save time with automated payment processes across borders
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={"100px"}
            className="flexCenterCenterColumn"
            gap={"40px"}>
            <Typography variant='h5' textAlign={"left"} mb={"10px"}>
              How it works
            </Typography>
            <Stepper alternativeLabel activeStep={7} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      variant='caption'>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
            mt={"120px"}
            className="flexCenterCenterColumn"
            gap={"20px"}>
            <Typography variant='h5' textAlign={"left"} mb={"20px"}>
              Ready to optimize your global accounts payables?
            </Typography>
            <Typography variant='body1' 
              sx={{ color: "grey", marginBottom: "20px" }} 
              textAlign={"left"}>
              Sign up now and take control of your international vendor payments!
            </Typography>
            <Link 
              href="https://business.lusid.ai/auth"
              target="_blank" rel="noopener noreferrer"
              >
              <Button variant='outlined'>Get started</Button>
            </Link>
          </Box>
        </Box>
      </Box>
		</Container>
  )
}

export default AccountsPayablePage;