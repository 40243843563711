import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
	Box,
	Button,
	Container, 
	Divider, 
	Grid, 
	Link, 
	Typography 
} from '@mui/material';

import anime from 'animejs/lib/anime.es.js';

// import Logo from "../../assets/icons/mini-logo-blue.png";
import HomeIcon from "../../assets/images/home2.jpg";
import CurrencyImage from "../../assets/currencyImage.png";

import SolutionImg from "../../assets/images/solution2.jpg";
import SS from "../../assets/ss.png";
// import EastIcon from '@mui/icons-material/East';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LanguageIcon from '@mui/icons-material/Language';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CableTwoToneIcon from '@mui/icons-material/CableTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';

import styles from "../../global.scss";
import "./styles.scss";

const HomePage = () => {
	const navigate = useNavigate();

	const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

	useEffect(() => {
		var timeLine1 = anime.timeline({
			easing: 'easeOutExpo',
			loop: true
		});

		timeLine1
		.add({
			targets: "#text1",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text2",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text3",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text4",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text5",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})

	}, []);

	// ------------------- miscellaneous -------------------
	const handleNavigate = (route) => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		navigate(route);
	}

  return (
		<Box className="home">
			<Container 
				maxWidth="xl">
				<Box className="home__top flexCenterSBRow">
					<Box className={"flex_CenterColumn"} 
						sx={{ 
							margin: { xs: "auto", sm: "auto", md: "0px", lg: "0px" },
							height: "80vh", 
							position: "relative"
						}}>
						<Typography 
							sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}>Operating system for<br/>
								Buyers & Suppliers</Typography>

						{/* <Box sx={{ height: "60px" }}>
							<Typography id="text1" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Spend Automation</Typography>
							<Typography id="text2" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Virtual Cards</Typography>
							<Typography id="text3" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Tokenization</Typography>
							<Typography id="text4" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Biometrics</Typography>
							<Typography id="text5" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Hyper-personalization</Typography>
						</Box> */}
{/* 
						<Typography variant='subtitle2'
							sx={{ color: "grey", marginTop: "30px"}}>
							A frictionless platform built for success of small and
								medium businesses. 
						</Typography> */}

						<Typography variant='subtitle2'
							sx={{ color: "grey", marginTop: "10px"}}>
							<br/>
							Maximize cashflow through a fully integrated Accounts
							Payables, <br/>Accounts Receivables, 
							Expense management, and advanced spend controls.
						</Typography>

						{/* <Typography variant='body1'
							sx={{ color: "grey", marginTop: "30px"}}>
								<ul>
									<li>Manage &amp; pay Suppliers effortlessly</li>
		<li>Collect payments from Buyers</li>
		<li>Track and optimize DPO.</li>
								</ul>
								</Typography> */}

						


						<Box className="flexCenter_Row"
							sx={{ gap: "30px", marginTop: "60px" }}>
							<Button
								variant="contained"
								onClick={() => handleNavigate("/contact-us")}>
								Contact sales
							</Button>
							<Button
								variant="outlined"
								onClick={() => handleNavigate("#solutions")}>
								Learn more
							</Button>
						</Box>

					</Box>
					<Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "60%", lg: "60%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
							src={HomeIcon}
							alt="home-icon"
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
				</Box>
			</Container>

				
				<Box
					marginBottom={"100px"}>
					<Container
						maxWidth="lg">
						<Typography
							textAlign={"center"}
							variant='h5'
							color={"grey"}
							marginBottom={"100px"}>
							Frictionless platform for small & medium businesses
						</Typography>

						<Typography
							textAlign={"center"}
							variant='h4'
							marginBottom={"20px"}>
							Pay & get paid
						</Typography>

						<img
							src={SS}
							alt='tablet-ss'
							width={"100%"}
							/>

						<Grid container spacing={2} sx={{ marginTop: "60px"}}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<AccountBalanceTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Pay with Account transfers</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Make payments using
standard ACH for free
or opt for same-day
ACH or instant
transfer.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<CreditCardTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Bill Payment by Card</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Make card payments
to vendors, regardless
of whether they accept
cards or not.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<DescriptionTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Receivable Invoices with options</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Generate and dispatch
invoices to customers
using various delivery
options.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<LocalAtmTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Skip check deposits entirely</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Get payments directly
deposited into your
bank account from
customers.
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>

					</Container>
				</Box>

			<Box>
				<Container
						maxWidth="xl">
								
				</Container>
			</Box>

			<Box className="home__products"
				id={"solutions"}>
				<Container
					maxWidth="xl">
					<Typography 
						sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}} 
						// className='home__links'
						textAlign={"center"}>Manage your cash inflows & outflows</Typography>

						{/* <Typography variant='body1'
							sx={{ color: "grey", marginTop: "30px"}}>
								<ul>
									<li>Manage &amp; pay Suppliers effortlessly</li>
		<li>Collect payments from Buyers</li>
		<li>Track and optimize DPO.</li>
								</ul>
								</Typography> */}
					

					<Typography
						// variant='h5'
						sx={{ fontSize: { xs: "22px", sm: "22px", md: "30px", lg: "30px" }}}
						color={"grey"}
						marginTop={"40px"}
						textAlign={"center"}
						marginBottom={"40px"}
						>
						One platform fully integrated to provide advanced money movement options and
						access to working capital.
					</Typography>
					
					<Grid container spacing={4} sx={{ marginTop: "40px"}}>
						<Grid item xs={12} sm={12} md={6} lg>
							<Box className="home__products-cards flexCenterSBColumn">
								<Box className="flex_CenterColumn" 
									sx={{ gap: "20px", height: "calc(100% - 50px)" }}>
									<Typography
										variant='h6'
										textAlign={"center"}>
										Accounts Payable
									</Typography>
									<Typography
										variant='body1'
										textAlign="justify"
										color={"grey"}>
										Simplify your vendor payments globally with our Accounts Payable solution. Make payments in over 38 currencies across 180 countries, automate recurring payments, and implement custom approval workflows for enhanced control.
										<br/>
										<ul style={{ marginLeft: "40px" }}>
											<li>Global payment capabilities</li>
											<li>Custom approval workflows</li>
											<li>Multiple payment speed options</li>
											<li>Recurring payment automation</li>
										</ul>
									</Typography>
									<Link
										sx={{ cursor: "pointer" }}
										onClick={() => {
											document.body.scrollTop = 0; // For Safari
											document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
											navigate("/products/accounts-payable")
										}}>
										Learn more
									</Link>
								</Box>
							</Box>
						</Grid>	
						<Grid item xs={12} sm={12} md={6} lg>
							<Box className="home__products-cards flexCenterSBColumn">
								<Box className="flex_CenterColumn" 
									sx={{ gap: "20px", height: "calc(100% - 50px)" }}>
									<Typography
										variant='h6'
										textAlign="center">
										Accounts Receivable
									</Typography>
									<Typography
										variant='body1'
										textAlign="justify"
										color={"grey"}>
										Optimize your cash flow by streamlining your payment collection process. Receive payments from customers worldwide in over 38 currencies across 180 countries using simple payment links that can be embedded in invoices or sent via email and mobile messages.
										<br/>
										<ul style={{ marginLeft: "40px" }}>
											<li>Global payment acceptance</li>
											<li>Easy payment links</li>
											<li>Invoice tracking and reminders</li>
											<li>Multi-currency support</li>
										</ul>
									</Typography>
									<Link
										sx={{ cursor: "pointer" }}
										onClick={() => {
											document.body.scrollTop = 0; // For Safari
											document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
											navigate("/products/accounts-receivable")
										}}>
										Learn more
									</Link>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg>
							<Box className="home__products-cards flexCenterSBColumn"
								onClick={() => navigate("/products/expense-management")}>
								<Box className="flex_CenterColumn" 
									sx={{ gap: "20px", height: "calc(100% - 50px)" }}>
									<Typography
										variant='h6'
										textAlign="center">
										Expense Management
									</Typography>
									<Typography
										variant='body1'
										textAlign="justify"
										color={"grey"}>
										Efficiently manage business expenses for your global workforce. Allow employees, contractors, and gig workers to submit expenses easily, implement custom approval workflows, and reimburse in local currencies across 180 countries.
										<br/>
										<ul style={{ marginLeft: "40px" }}>
											<li>Global reimbursement capabilities</li>
											<li>Receipt capture and management</li>
											<li>Custom approval workflows</li>
											<li>Expense categorization and reporting</li>
										</ul>
									</Typography>
									<Link
										sx={{ cursor: "pointer" }}
										onClick={() => {
											document.body.scrollTop = 0; // For Safari
											document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
											navigate("/products/expense-management")
										}}>
										Learn more
									</Link>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box>
				<Container
					maxWidth="xl">
					<Box className="flexCenterSBRow">
						<Box 
							className="flexCenterCenterRow" 
							sx={{ minWidth: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
								width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
								backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
							<img
								src={SolutionImg}
								alt="home-icon"
								style={{width: "100%", height: "100%", objectFit: "fill" }}
								/>
						</Box>
						<Box className={"flex_CenterColumn"} 
							sx={{ 
								margin: { xs: "auto", sm: "auto", md: "0px", lg: "0px" },
								height: "80vh", 
								position: "relative"
							}}>
							<Typography 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}>
								Solutions for Banks
							</Typography>
							<Typography variant='h6'
								sx={{ color: "grey", marginTop: "20px"}}>
								With our Software-as-a-Service, we enable our cilents to deliver comprehensive services to their business customers.
							</Typography>
				

					</Box>

				</Box>
				</Container>
			</Box>

			<Box className="home__solutions">
				<Container
					maxWidth="xl">
					

					<Box mt={"50px"} mb={"00px"} className="flexCenterCenterColumn" sx={{ gap: "50px" }}>
						<Typography variant='h5' textAlign={"center"}>
							Experience the benefits of our cutting-edge technology
						</Typography>
						
						<Grid container spacing={2} sx={{ marginBottom: "20px"}}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<MonitorHeartTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Real-time Transaction Monitoring</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Monitor and track transactions in real-time for enhanced security and fraud prevention
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<AssessmentTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Customizable Reporting and Analytics</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Generate detailed reports and gain valuable insights through customizable analytics tools
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<ShieldTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Secure Data Encryption</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Protect sensitive customer information with advanced encryption technology
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<CableTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Comprehensive API Integration</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Seamlessly integrate our software with partner Banks and Card Networks
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>

						<Button variant='outlined'
							onClick={() => handleNavigate("/products")}>
							Our Products
						</Button>
					</Box>
				</Container>
			</Box>

			<Box className="home__solutions"
				mb="100px">
				<Container
					maxWidth="xl">
						<Typography 
							textAlign={"center"}
							sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}>
							Our global reach
						</Typography>
						<Box
							// className="flexCenterCenterRow"
							width={"100%"}
							gap={"20px"}
							sx={{
								display: "flex",
								alignItems: "center",
								flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }
							}}>
							<Box
								className="flexCenterSBRow">
									<Box></Box>
								<img
									src={CurrencyImage}
									alt='currencies-image'
									style={{
										width: "100%"
									}}
									/>
							</Box>
							<Box>
								<Typography
									variant='h6'
									color={"grey"}
									sx={{ fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" }, 
										fontWeight: 500 }}>
									Support your international partners with currency conversion in 38+ currencies, including:
								</Typography>
								<Typography
									variant='h6'
									sx={{ fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" }, 
										fontWeight: 500 }}
									color={"grey"}>
									<ul style={{ marginLeft: "40px" }}>
										USD (US Dollar)<br/>
										EUR (Euro)<br/>
										GBP (British Pound)<br/>
										JPY (Japanese Yen)<br/>
										AUD (Australian Dollar)<br/>
										AED (Emirati Dirham)<br/>
										and many more...<br/>
									</ul>
								</Typography>
								<Typography
									variant='h6'
									color={"grey"}
									sx={{ fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" }, 
										fontWeight: 500 }}>
									Move money to/from 180 countries, supporting your business operations across continents.
								</Typography>
							</Box>
						</Box>
				</Container>
			</Box>

			<Box className="home__developers" id={"developers"}>
				<Box className="flexCenterCenterColumn" 
					sx={{ gap: "10px", backgroundColor: "#f8f8f8", padding: "60px" }}>
					<Typography variant='h4' className='home__links'>Developers</Typography>
					<Typography variant='h6' sx={{ color: styles["primary_dark"]}} mb={"20px"}>Integrate Lusid APIs in your products</Typography>
					<Link 
						href="https://developer.lusid.ai"
						target="_blank" rel="noopener noreferrer"
						>
						<Button
							variant='outlined'>Developer Portal</Button>
					</Link>
				</Box>	
			</Box>
		</Box>
  )
}

export default HomePage;